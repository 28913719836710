import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

import Arrow from "../../../assets/svg/icons/ArrowE.svg";
import Contact from "../../../assets/svg/animations/Contact.svg";

import {
  COLORS
} from "../../../theme/style-constants";


const loop = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const ContactContainer = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  animation: ${ loop } 4s linear infinite;
  animation-play-state: paused;
`;

export const AnimatedCircle = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  transition: all 1s ease-in-out;
`;

export const StyledContact = styled(Contact)`
  width: 100%;
  height: 100%;
  overflow: visible;
  animation: ${ loop } 10s linear infinite;

  text {
    font-size: 13px;
    fill: ${ COLORS.text.dark };
    letter-spacing: 1px;
  }
`;

export const StyledArrow = styled(Arrow)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  stroke: ${ COLORS.text.dark };
  fill: none;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in-out;
`;

export const StyledInternalLink = styled(Link)`
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  text-decoration: none;
  transform-origin: center;
`;

export const StyledExternalLink = styled.a`
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  text-decoration: none;
  transform-origin: center;
`;
