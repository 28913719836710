/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import GlobalStyle from "../../theme/style-global";

// import CookieBanner from "../CookieBanner";
import Footer from "../Footer";
import Header from "../Header";

import {
  StyledBackgroundCanvas,
  StyledMain
} from "./style";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <GlobalStyle />

      <Header siteTitle={ data.site.siteMetadata?.title || `Title` } />

      <StyledBackgroundCanvas
        id="background"
      />

      <StyledMain>
        { children }
      </StyledMain>

      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
