import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

import {
  COLORS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledMenuButton = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 65px;
  max-width: 65px;
  height: 60px;
  cursor: pointer;
  z-index: 11;

  @media (min-width: ${ SCREENS.laptop }) {
    display: none;
  }
`;

export const StyledMenuLabel = styled.span`
  display: inline-block;
  font-size: 0.7rem;
  transform: translateX(-30%);
  letter-spacing: 4px;
  z-index: 1;
`;

export const StyledMenuLabelOpen = styled.span`
  display: inline-block;
  opacity: ${ props => props.menuOpen ? `0` : `1` };
  transform: ${ props => props.menuOpen ? `translateX(0) scaleY(0)` : `translateX(0) scaleY(1)` };
  transition: transform 0.5s, opacity 0.5s;
  will-change: transform, opacity;
`;

export const StyledMenuLabelClose = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  opacity: ${ props => props.menuOpen ? `1` : `0` };
  transform: ${ props => props.menuOpen ? `translateX(0) scaleY(1)` : `translateX(0) scaleY(0)` };
  transition: transform 0.5s, opacity 0.5s;
  will-change: transform, opacity;
`;

export const StyledMenuCircleWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  width: 40px;
  height: 40px;
  margin: auto;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const StyledMenuCircle = styled.svg`
  width: 100%;
  height: auto;
`;

export const StyledMenuCircleBG = styled.g`
  opacity: 0.2;

  path {
    fill: none;
    stroke: ${ COLORS.text.dark };
    stroke-width: 1;
    stroke-dashoffset: 0;
    stroke-dasharray: none;
    opacity: 1;
    visibility: inherit;
  }
`;

export const StyledMenuCircleOver = styled.g`
  path {
    fill: none;
    stroke: ${ COLORS.text.dark };
    stroke-width: 1;
    visibility: inherit;
    stroke-dashoffset: ${ props => props.menuOpen ? `84.5634` : `-84.5634` };
    stroke-dasharray: ${ props => props.menuOpen ? `99.4864px, 109.486px` : `14.923px, 109.486px` };
    opacity: 1;
    transition: all 1.5s;
    transition-delay: 0.2s;
    will-change: stroke-dashoffset, stroke-dasharray;
  }
`;

export const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  visibility: ${ props => props.menuOpen ? `visible` : `hidden` };
  height: 100vh;
  width: 100vw;
  background-color: ${ COLORS.background.light };
  opacity: ${ props => props.menuOpen ? `1` : `0` };
  overflow-y: hidden;
  transition: all 0.5s;
  transition-delay: 0.2s;
`;

export const Container = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;
  padding-top: 124px;
`;

export const StyledNavigation = styled.nav`
  margin: 0 0 2rem;
`;

export const StyledInternalLink = styled(Link)`
  display: block;
  margin: 0 0 1rem;
  color: ${ COLORS.text.dark };
  font-size: 1.3rem;
  text-decoration: none;

  &:visited {
    color: ${ COLORS.text.dark };
  }
`;

export const ContactContainer = styled.div`
  margin: 2rem 0 0;
  padding: 2rem 0 0;
  border-top: 1px solid ${ COLORS.border.dark };
`;

export const StyledContact = styled.div`
  margin: 0 0 2rem;
`;

export const StyledLocation = styled.div``;

export const StyledExternalLink = styled.a`
  display: block;
  margin: 0.5rem 0;
  color: ${ COLORS.text.dark };
  text-decoration: none;

  &:visited {
    color: ${ COLORS.text.dark };
  }
`;

export const StyledLocationItem = styled.span`
  display: block;
  margin: 0.5rem 0;
  color: ${ COLORS.text.dark };
`;
