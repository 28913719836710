import * as React from "react";

import {
  StyledColumn,
  StyledHeading,
  StyledLink,
  StyledNavigation
} from "./style";


const ContactColumn = () => {
  return (
    <StyledColumn>
      <StyledHeading>
        Contact
      </StyledHeading>
      <nav>
        <StyledNavigation>
          <li>
            <StyledLink
              href="tel:+14164560428"
            >
              +1 416 456 0428
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href="mailto:hello@explore.agency"
            >
              hello@explore.agency
            </StyledLink>
          </li>
          <li>
            &nbsp;
          </li>
          <li>
            332 Horner Ave. Unit 100
          </li>
          <li>
            Toronto, ON
          </li>
          <li>
            M8W 1Z3
          </li>
        </StyledNavigation>
      </nav>
    </StyledColumn>
  )
}


export default ContactColumn;
