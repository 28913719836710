import styled from "styled-components";
import { Link } from "gatsby";

import {
  COLORS
} from "../../../../theme/style-constants";


export const StyledHeading = styled.h6`
  position: relative;
  margin: 0 0 1.5rem;
  padding: 0 0 0.5rem;
  color: ${ COLORS.text.orange };
`;

export const StyledColumn = styled.div`
  grid-area: links;
`;

export const StyledNavigation = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const StyledLink = styled(Link)`
  color: ${ COLORS.text.light };
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${ COLORS.text.orange } !important;
  }

  &:visited {
    color: ${ COLORS.text.light };
  }
`;
