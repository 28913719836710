import * as React from "react";

import {
  AnimatedCircle,
  ContactContainer,
  StyledArrow,
  StyledContact,
  StyledExternalLink,
  StyledInternalLink
} from "./style";


const AnimatedButton = ({
  isExternal,
  linkLocation
}) => {
  return (
    <>
      { isExternal &&
        <StyledExternalLink>
        </StyledExternalLink>
      }
      { !isExternal &&
        <StyledInternalLink
          to={ linkLocation }
        >
          <StyledArrow />
          <ContactContainer>
            <AnimatedCircle>
              <StyledContact />
            </AnimatedCircle>
          </ContactContainer>
        </StyledInternalLink>
      }
    </>
  )
}


export default AnimatedButton;
