import { createGlobalStyle } from "styled-components";

import {
  COLORS,
  SCREENS
} from "./style-constants";


const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: content-box;
  }

  body {
    background: ${ COLORS.background.light };
    font-family: Helvetica, sans-serif;
    overflow-x: hidden;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 300;
  }

  h2 {
    font-size: 2rem;
    font-weight: 300;
  }

  h3 {
    font-weight: 300;
  }

  h5 {
    font-size: 1.15rem;
    font-weight: 300;
  }

  h6 {
    font-size: 1.1rem;
    font-weight: 300;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
  }

  li {
    margin: 0.25rem 0;
  }

  @media (min-width: ${ SCREENS.laptop }) {
    h1 {
      font-size: 4rem;
      font-weight: 300;
    }
  
    h2 {
      font-size: 2.5rem;
      font-weight: 300;
    }
  
    h5 {
      font-size: 1.15rem;
      font-weight: 300;
    }
  
    h6 {
      font-size: 1.1rem;
      font-weight: 300;
    }
  
    li {
      margin: 0.25rem 0;
    }
  }
`;


export default GlobalStyle;
