import * as React from "react";

import SecondaryButton from "../../../Button/SecondaryButton";

import {
  StyledColumn,
  StyledDescription,
  StyledHeading
} from "./style";


const ConsultationColumn = () => {
  return (
    <StyledColumn>
      <StyledHeading>
        Schedule Your Consultation
      </StyledHeading>
      <StyledDescription>
        <span>
          Schedule your marketing consultation today.
        </span>
      </StyledDescription>
      <SecondaryButton
        linkLocation="/contact"
        linkText="Schedule Now"
        darkBackground
      />
    </StyledColumn>
  )
}


export default ConsultationColumn;
