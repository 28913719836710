import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledCopyright = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.largeDesktop };
  width: 90%;
  margin: 0 auto;
  padding: ${ PADDINGS.container.copyright };
  text-align: center;
  font-size: 0.9rem;
`;
