import * as React from "react";
import { useEffect, useState } from "react";

import AnimatedButton from "../Button/AnimatedButton";
import Burger from "./Burger";
import Navigation from "./Navigation";

import {
  ButtonContainer,
  Container,
  SiteLogo,
  StyledHeader,
  StyledLink
} from "./style";


const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [pageTop, setPageTop] = useState(true);
  const [headerVisible, setHeaderVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setPageTop(currentScrollPos < 200);
    setHeaderVisible((prevScrollPos > currentScrollPos) || currentScrollPos < 10);

    setPrevScrollPos(currentScrollPos);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, pageTop, headerVisible, handleScroll]);

  return (
    <StyledHeader
      headerVisible={ headerVisible }
      pageTop={ pageTop }
    >
      <Container>
        <StyledLink
          to="/"
        >
          <SiteLogo />
        </StyledLink>
        <Navigation />
        <ButtonContainer>
          <AnimatedButton
            linkLocation="/contact"
          />
        </ButtonContainer>
        <Burger />
      </Container>
    </StyledHeader>
  )
}


export default Header;
