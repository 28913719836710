import * as React from "react";

import {
  StyledNav,
  StyledLink
} from "./style";


const Navigation = ({
  isMobile
}) => {
  return (
    <StyledNav
      isMobile={ isMobile }
    >
      <StyledLink to="/about">About</StyledLink>
      <StyledLink to="/services">Services</StyledLink>
      <StyledLink to="/portfolio">Portfolio</StyledLink>
      <StyledLink to="/blog">Blog</StyledLink>
      <StyledLink to="/contact">Contact</StyledLink>
    </StyledNav>
  )
}


export default Navigation;
