import * as React from "react";
import { useState } from "react";

import AnimatedButton from "../../Button/AnimatedButton";

import {
  ContactContainer,
  Container,
  StyledExternalLink,
  StyledInternalLink,
  StyledMenu,
  StyledMenuButton,
  StyledMenuCircle,
  StyledMenuCircleBG,
  StyledMenuCircleOver,
  StyledMenuCircleWrapper,
  StyledContact,
  StyledMenuLabel,
  StyledMenuLabelClose,
  StyledMenuLabelOpen,
  StyledLocation,
  StyledLocationItem,
  StyledNavigation
} from "./style";


const Burger = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <StyledMenuButton
      onClick={ toggleMenu }
    >
      <StyledMenuLabel>
        <StyledMenuLabelOpen
          menuOpen={ menuOpen }
        >
          MENU
        </StyledMenuLabelOpen>
        <StyledMenuLabelClose
          menuOpen={ menuOpen }
        >
          CLOSE
        </StyledMenuLabelClose>
      </StyledMenuLabel>
      <StyledMenuCircleWrapper>
        <StyledMenuCircle xlmns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
          <StyledMenuCircleBG>
            <path d="M4,30.51a19.21,19.21,0,1,0,0-21" transform="translate(-3.5, -0.3)"></path>
          </StyledMenuCircleBG>
          <StyledMenuCircleOver
            menuOpen={ menuOpen }
          >
            <path d="M4,30.51a19.21,19.21,0,1,0,0-21" transform="translate(-3.5, -0.3)"></path>
          </StyledMenuCircleOver>
        </StyledMenuCircle>
      </StyledMenuCircleWrapper>
      <StyledMenu
        menuOpen={ menuOpen }
      >
        <Container>
          <StyledNavigation>
            <StyledInternalLink to="/about">About</StyledInternalLink>
            <StyledInternalLink to="/services">Services</StyledInternalLink>
            <StyledInternalLink to="/portfolio">Portfolio</StyledInternalLink>
            <StyledInternalLink to="/blog">Blog</StyledInternalLink>
            <StyledInternalLink to="/contact">Contact</StyledInternalLink>
          </StyledNavigation>
          <AnimatedButton
            linkLocation="/contact"
          />
          <ContactContainer>
            <StyledContact>
              <StyledExternalLink
                href="tel:+14164560428"
              >
                +1 (416) 456-0428
              </StyledExternalLink>
              <StyledExternalLink
                href="mailto:hello@explore.agency"
              >
                hello@explore.agency
              </StyledExternalLink>
            </StyledContact>
            <StyledLocation>
              <StyledLocationItem>332 Horner Ave. Unit 100</StyledLocationItem>
              <StyledLocationItem>Toronto, Ontario</StyledLocationItem>
              <StyledLocationItem>M8W 1Z3</StyledLocationItem>
            </StyledLocation>
          </ContactContainer>
        </Container>
      </StyledMenu>
    </StyledMenuButton>
  )
}


export default Burger;
