import styled from "styled-components";

import {
  COLORS
} from "../../../../theme/style-constants";


export const StyledHeading = styled.h6`
  position: relative;
  margin: 0 0 1.5rem;
  padding: 0 0 0.5rem;
  color: ${ COLORS.text.orange };
`;

export const StyledColumn = styled.div`
  grid-area: consultation;
`;

export const StyledDescription = styled.div`
  margin: 0 0 2rem;
`;
