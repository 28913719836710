export const COLORS = {
  background: {
    dark: `#231F20`,
    light: `#BDBDBD`,
    gray: `#B6B8B9`,
    lime: `#00FF45`,
    orange: `#9FFF00`,
  },
  border: {
    white: `#FFFFFF`,
    dark: `#231F20`,
    light: `#BDBDBD`,
    gray: `#B6B8B9`,
    lime: `#00FF45`,
    orange: `#9FFF00`,
  },
  text: {
    black: `#000000`,
    dark: `#231F20`,
    light: `#FFFFFF`,
    lime: `#00FF45`,
    orange: `#9FFF00`,
    error: `#991B1B`,
  },
};

export const CONTAINERS = {
  mobile: `300px`,
  tablet: `568px`,
  post: `750px`,
  laptop: `960px`,
  desktop: `1024px`,
  largeDesktop: `1280px`,
}

export const PADDINGS = {
  container: {
    copyright: `2rem 0`,
    footer: `5rem 0`,
    header: `2rem 0`,
    section: {
      mobile: `3rem 0`,
      laptop: `5rem 0`,
    },
  },
};

export const SCREENS = {
  tablet: `640px`,
  laptop: `1025px`,
  desktop: `1280px`,
  largeDesktop: `1536px`,
};
