import * as React from "react";

import {
  StyledColumn,
  StyledHeading,
  StyledLink,
  StyledNavigation
} from "./style";


const LinksColumn = () => {
  return (
    <StyledColumn>
      <StyledHeading>
        Site Links
      </StyledHeading>
      <nav>
        <StyledNavigation>
          <li>
            <StyledLink
              to="/"
            >
              Home
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/about"
            >
              About
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/services"
            >
              Services
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/portfolio"
            >
              Portfolio
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/blog"
            >
              Blog
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/contact"
            >
              Contact
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/legal/privacy-policy"
            >
              Privacy Policy
            </StyledLink>
          </li>
          <li>
            <StyledLink
              to="/legal/terms-of-service"
            >
              Terms of Service
            </StyledLink>
          </li>
        </StyledNavigation>
      </nav>
    </StyledColumn>
  )
}


export default LinksColumn;
