import styled from "styled-components";
import { Link } from "gatsby";

import {
  COLORS,
  SCREENS
} from "../../../theme/style-constants";


export const StyledNav = styled.nav`
  display: ${ props => props.isMobile ? 'flex' : 'none' };
  flex-direction: column;

  @media (min-width: ${ SCREENS.laptop }) {
    display: ${ props => props.isMobile ? 'none' : 'flex' };
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledLink = styled(Link)`
  margin: 0 2rem;
  color: ${ COLORS.text.dark };
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${ COLORS.text.orange } !important;
  }

  &:visited {
    color: ${ COLORS.text.dark };
  }
`;