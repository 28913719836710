import styled from "styled-components";
import { Link } from "gatsby";

import Explore from "../../assets/svg/Explore.svg";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../theme/style-constants";


export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${ props => props.pageTop ? `transparent` : COLORS.background.light };
  transform: ${ props => props.headerVisible ? `translateY(0)` : `translateY(-200px)` };
  transition: all 0.3s ease-in-out;
  z-index: 1000;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: ${ CONTAINERS.largeDesktop };
  width: 90%;
  margin: 0 auto;
  padding: ${ PADDINGS.container.header };
`;

export const StyledLink = styled(Link)`
  height: 3rem;
  margin: 0;
  color: white;
  text-decoration: none;
  line-height: 0;
  transition: all 0.2s ease-in-out;
  z-index: 1001;

  @media (min-width: ${ SCREENS.tablet }) {
    height: 4rem;
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const SiteLogo = styled(Explore)`
  height: 100%;
`;

export const ButtonContainer = styled.div`
  display: none;

  @media (min-width: ${ SCREENS.laptop }) {
    display: block;
  }
`;
