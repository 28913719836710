import styled from "styled-components";

import {
  SCREENS
} from "../../theme/style-constants";


export const StyledMain = styled.main`
  position: relative;
  padding-top: 124px;

  @media (min-width: ${ SCREENS.laptop }) {
    padding-top: 164px;
  }
`;

export const StyledBackgroundCanvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: -1;
`;
