import styled from "styled-components";

import {
  COLORS,
  CONTAINERS,
  PADDINGS,
  SCREENS
} from "../../theme/style-constants";


export const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${ COLORS.background.dark };
  color: ${ COLORS.text.light };
`;

export const Container = styled.div`
  max-width: ${ CONTAINERS.largeDesktop };
  width: 90%;
  margin: 0 auto;
  padding: ${ PADDINGS.container.footer };
`;

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "links social"
    "contact contact"
    "consultation consultation";
  grid-gap: 3rem 1rem;

  @media (min-width: ${ SCREENS.laptop }) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "links social contact contact consultation";
  }
`;
