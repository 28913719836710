import * as React from "react";

import Copyright from "./Copyright";
import ConsultationColumn from "./Column/ConsultationColumn";
import ContactColumn from "./Column/ContactColumn";
import LinksColumn from "./Column/LinksColumn";
import SocialColumn from "./Column/SocialColumn";

import {
  Container,
  FooterGrid,
  StyledFooter
} from "./style";


const Footer = () => {
  return (
    <>
      <StyledFooter>
        <Container>
          <FooterGrid>
            <LinksColumn />
            <SocialColumn />
            <ContactColumn />
            <ConsultationColumn />
          </FooterGrid>
        </Container>
      </StyledFooter>
      <Copyright />
    </>
  )
}


export default Footer;
