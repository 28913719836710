import * as React from "react";

import {
  StyledArrow,
  StyledExternalLink,
  StyledIcon,
  StyledInternalLink,
  StyledLine,
  StyledLinkText
} from "./style";


const SecondaryButton = ({
  isExternal,
  linkLocation,
  linkText,
  darkBackground
}) => {
  return (
    <>
      { isExternal &&
        <StyledExternalLink
          href={ linkLocation }
          darkBackground={ darkBackground }
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledLinkText>{ linkText }</StyledLinkText>
          <StyledIcon>
            <StyledLine
              darkBackground={ darkBackground }
            />
            <StyledArrow
              darkBackground={ darkBackground }
            />
          </StyledIcon>
        </StyledExternalLink>
      }
      { !isExternal &&
        <StyledInternalLink
          to={ linkLocation }
          darkBackground={ darkBackground }
        >
          <StyledLinkText>{ linkText }</StyledLinkText>
          <StyledIcon>
            <StyledLine
              darkBackground={ darkBackground }
            />
            <StyledArrow
              darkBackground={ darkBackground }
            />
          </StyledIcon>
        </StyledInternalLink>
      }
    </>
  )
}


export default SecondaryButton;
