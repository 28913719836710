import * as React from "react";

import {
  StyledColumn,
  StyledHeading,
  StyledLink,
  StyledNavigation
} from "./style";


const SocialColumn = () => {
  return (
    <StyledColumn>
      <StyledHeading>
        Social Media
      </StyledHeading>
      <nav>
        <StyledNavigation>
          <li>
            <StyledLink
              href="https://www.instagram.com/explore.agency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href="https://www.facebook.com/explorelagency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href="https://www.linkedin.com/company/explore-agency"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href="https://twitter.com/ExploreAgencyTO"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href="https://www.youtube.com/channel/UCPnnTJgxebDFkKwFmVBYZ0g"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </StyledLink>
          </li>
          <li>
            <StyledLink
              href="https://www.tiktok.com/@explore.agency"
              target="_blank"
              rel="noopener noreferrer"
            >
              TikTok
            </StyledLink>
          </li>
        </StyledNavigation>
      </nav>
    </StyledColumn>
  )
}


export default SocialColumn;
