import * as React from "react";

import {
  Container,
  StyledCopyright
} from "./style";


const Copyright = () => {
  return (
    <StyledCopyright>
      <Container>
        <span>&copy; { new Date().getFullYear() } Explore Agency. All Rights Reserved.</span>
      </Container>
    </StyledCopyright>
  )
}


export default Copyright;
